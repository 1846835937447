import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import {
  renderSEO,
  ROUTES,
  LoadingIndicator,
  isBrowser,
} from "@lesmills/gatsby-theme-common"

const NotFoundSection = React.lazy(() => import("../components/404"))

type Props = {|
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const NotFoundPage = ({ pageContext, data }: Props) => {
  if (!data) return null
  const lang = pageContext.unPublishedLang || pageContext.lang
  const prismicNotFoundPage = data.prismicNotFoundPage || {}
  const pageContent = prismicNotFoundPage.data || {}
  const layoutData = (data.prismicLayout && data.prismicLayout.data) || {}

  return (
    <>
      {renderSEO(pageContent, ROUTES(lang).NOT_FOUND, lang)}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <NotFoundSection
            data={pageContent}
            layoutData={layoutData}
            lang={lang}
          />
        </React.Suspense>
      )}
    </>
  )
}

export const query = graphql`
  query($lang: String) {
    prismicNotFoundPage(lang: { eq: $lang }) {
      data {
        back_to_home_link {
          raw
          text
        }
        background_photo {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        go_back_button {
          text
          raw
        }
        help_message {
          raw
        }
        main_content {
          raw
        }
        not_found_photo {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        sub_content {
          raw
        }
        title {
          raw
          text
        }
      }
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        les_mills_plus_logo {
          alt
          url
        }
      }
    }
  }
`

export default withPreview(NotFoundPage)
