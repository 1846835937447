import React from "react"
import { withPreview } from "gatsby-source-prismic"
import type { UserType } from "@lesmills/gatsby-theme-common"

import {
  ROUTES,
  PublicRoute,
  renderSEO,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"
import { graphql } from "gatsby"

type Props = {|
  location: Object,
  pageContext: {
    id: string,
    lang: string,
  },
  user: Object,
  data: Object,
|}

import RefinedSaleLanding from "../components/RefinedSaleLandingPage"

const LandingPage = (props: Props) => {
  const { pageContext, data } = props || {}

  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicLayout = {}, prismicNewSalesLandingPage = {} } = data
  const salesLandingData = prismicNewSalesLandingPage.data || {}
  const layoutData = prismicLayout.data || {}
  const scriptsLandingPage = [DATA_LAYER_SCRIPT]
  const renderLandingPage = ({ user }: UserType) => {
    return (
      <>
        {renderSEO(
          salesLandingData,
          ROUTES(lang).SALES_LANDING,
          lang,
          scriptsLandingPage
        )}
        <RefinedSaleLanding
          data={salesLandingData}
          layoutData={layoutData}
          isNewSalesLandingPage={true}
          user={user}
          lang={lang}
        />
      </>
    )
  }

  return <PublicRoute component={renderLandingPage} {...props} lang={lang} />
}

export const query = graphql`
  query landingQuery($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        ...LayoutUnits
      }
    }
    prismicNewSalesLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicNewSalesLandingPageBodyPlatforms {
            items {
              platform_image {
                alt
                url
              }
            }
          }
        }
        body1 {
          ... on PrismicNewSalesLandingPageBody1Workouts {
            id
            items {
              workout_description {
                raw
                text
              }
              workout_icon {
                alt
                url
              }
            }
          }
        }
        body2 {
          ... on PrismicNewSalesLandingPageBody2Videos {
            items {
              embedded_video {
                url
                html
              }
              embedded_video_name {
                text
                raw
              }
              embedded_video_description {
                text
                raw
              }
              thumbnail_image {
                raw
              }
            }
          }
        }
        body3 {
          ... on PrismicNewSalesLandingPageBody3Featured {
            items {
              featured_in_logo {
                alt
                url
              }
            }
          }
        }
        body4 {
          ... on PrismicNewSalesLandingPageBody4StreamPlatforms {
            items {
              stream_platform_details {
                text
                raw
              }
              stream_platform_name {
                text
              }
              stream_platform_icon {
                url
                alt
              }
              stream_device_icon {
                url
                alt
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
            }
          }
        }
        body5 {
          ... on PrismicNewSalesLandingPageBody5Testimonials {
            items {
              testimonial_content {
                text
                raw
              }
              testimonial_owner_name {
                text
              }
              testimonial_quote {
                raw
                text
              }
              testimonial_rating
            }
            primary {
              testimonials_title {
                text
                raw
              }
            }
          }
        }
        body6 {
          ... on PrismicNewSalesLandingPageBody6Faqs {
            items {
              faq_answer {
                raw
                text
              }
              faq_question {
                raw
                text
              }
            }
          }
        }
        body7 {
          ... on PrismicNewSalesLandingPageBody7CommunityPhotos {
            items {
              community_photo {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              community_photo_hashtag {
                text
                raw
              }
              community_photo_owner_name {
                raw
                text
              }
            }
          }
        }
        body8 {
          ... on PrismicNewSalesLandingPageBody8FooterNavigations {
            items {
              footer_navigation_label {
                raw
                text
              }
            }
          }
          ... on PrismicNewSalesLandingPageBody8FooterSocials {
            items {
              footer_social_icon {
                alt
                url
              }
              footer_social_url {
                url
                slug
                raw
                link_type
                target
                type
              }
              icon {
                text
                raw
              }
            }
          }
        }
        body9 {
          ... on PrismicNewSalesLandingPageBody9HeaderNavigations {
            items {
              header_navigation_item {
                raw
                text
              }
              id
            }
          }
        }
        body10 {
          ... on PrismicNewSalesLandingPageBody10ModulesConfiguration {
            items {
              display
              module_id
            }
          }
        }
        cancel_free_trial_note {
          text
          raw
        }
        discover_button_label {
          raw
          text
        }
        embedded_video_title {
          raw
          text
        }
        embedded_video_subtitle {
          raw
          text
        }
        faq_title {
          raw
          text
        }
        featured_in_title {
          raw
          text
        }
        finish_sign_up {
          raw
          text
        }
        footer_copyright {
          raw
          text
        }
        header_logo {
          alt
          url
        }
        header_logo_url {
          link_type
          raw
          size
          slug
          tags
          target
          type
          uid
          url
        }
        hero_subtitle {
          raw
          text
        }
        hero_logo {
          alt
          url
        }
        hero_image {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        hero_video {
          raw
        }
        hero_title {
          raw
          text
        }
        hero_subtitle {
          raw
          text
        }
        join_community_subtitle {
          text
        }
        join_community_title {
          text
        }
        join_us_content {
          raw
          text
        }
        unlimited_access_text {
          raw
          text
        }
        try_free_trial_button {
          raw
          text
        }
        lmod_lpbc_err {
          raw
          text
        }
        page_author {
          raw
          text
        }
        page_description {
          raw
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        platform_support_title {
          text
          raw
        }
        see_prices_button_label {
          text
          raw
        }
        start_free_trial_button_label {
          text
          raw
        }
        stream_photo {
          alt
          url
        }
        stream_subtitle {
          text
        }
        stream_title {
          text
          raw
        }
        toast_title {
          text
        }
        try_the_app_subtitle {
          raw
          text
        }
        try_the_app_title {
          text
        }
        try_the_app_image {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        workout_title {
          text
        }
        challenge_yourself_title {
          raw
          text
        }
        challenge_yourself_subtitle {
          raw
          text
        }
        challenge_yourself_features {
          challenge_yourself_feature {
            raw
            text
          }
        }
        challenge_yourself_image {
          url
          alt
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        sign_up_button {
          raw
          text
        }
        extra_info_image {
          url
          alt
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        extra_info_content {
          raw
          text
        }
      }
    }
  }
`

export default withPreview(LandingPage)
