import React from "react"
import { withPreview } from "gatsby-source-prismic"

import { renderSEO, DATA_LAYER_SCRIPT } from "@lesmills/gatsby-theme-common"
import { graphql } from "gatsby"

import RefinedSaleLanding from "../components/RefinedSaleLandingPage"

type Props = {|
  data: Object,
  pageContext: {
    id: string,
    lang: string,
  },
  location: object,
|}

const MarketingSalesLandingPage = (props: Props) => {
  const { pageContext, location, data } = props || {}
  const lang = pageContext.unPublishedLang || pageContext.lang
  const scriptsMarketingPage = [DATA_LAYER_SCRIPT]

  if (!data) return null

  const marketingSLDData =
    data.prismicMarketingSalesLandingPages &&
    data.prismicMarketingSalesLandingPages.data
  const layoutData = (data.prismicLayout && data.prismicLayout.data) || {}

  return (
    <>
      {renderSEO(
        marketingSLDData,
        location.pathname,
        lang,
        scriptsMarketingPage
      )}
      <RefinedSaleLanding
        data={marketingSLDData}
        lang={lang}
        isNewSalesLandingPage={true}
        layoutData={layoutData}
        isMarketingSLP
      />
    </>
  )
}

export const query = graphql`
  query marketingSLDQuery($lang: String, $uid: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        ...LayoutUnits
      }
    }
    prismicMarketingSalesLandingPages(lang: { eq: $lang }, uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicMarketingSalesLandingPagesBodyPlatforms {
            items {
              platform_image {
                thumbnails {
                  mobile {
                    alt
                    url
                  }
                }
                url
              }
            }
          }
        }
        body1 {
          ... on PrismicMarketingSalesLandingPagesBody1Workouts {
            id
            items {
              workout_description {
                raw
                text
              }
              workout_icon {
                alt
                url
              }
            }
          }
        }
        body2 {
          ... on PrismicMarketingSalesLandingPagesBody2Videos {
            items {
              embedded_video {
                html
              }
              embedded_video_name {
                raw
                text
              }
              embedded_video_description {
                text
                raw
              }
              thumbnail_image {
                raw
                text
              }
            }
          }
        }
        body10 {
          ... on PrismicMarketingSalesLandingPagesBody10ModulesConfiguration {
            items {
              display
              module_id
            }
          }
        }
        body3 {
          ... on PrismicMarketingSalesLandingPagesBody3Featured {
            items {
              featured_in_logo {
                alt
                url
              }
            }
          }
        }
        body4 {
          ... on PrismicMarketingSalesLandingPagesBody4StreamPlatforms {
            items {
              stream_platform_details {
                raw
                text
              }
              stream_platform_name {
                raw
                text
              }
              stream_platform_icon {
                url
                alt
              }
              stream_device_icon {
                url
                alt
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
            }
          }
        }
        body5 {
          ... on PrismicMarketingSalesLandingPagesBody5Testimonials {
            items {
              testimonial_content {
                raw
                text
              }
              testimonial_owner_name {
                raw
                text
              }
              testimonial_quote {
                raw
                text
              }
              testimonial_rating
            }
            primary {
              testimonials_title {
                raw
                text
              }
            }
          }
        }
        body6 {
          ... on PrismicMarketingSalesLandingPagesBody6Faqs {
            items {
              faq_answer {
                raw
                text
              }
              faq_question {
                raw
                text
              }
            }
          }
        }
        body7 {
          ... on PrismicMarketingSalesLandingPagesBody7CommunityPhotos {
            items {
              community_photo {
                url
                alt
                thumbnails {
                  mobile {
                    alt
                    url
                  }
                }
              }
              community_photo_hashtag {
                raw
                text
              }
              community_photo_owner_name {
                raw
                text
              }
            }
          }
        }
        body8 {
          ... on PrismicMarketingSalesLandingPagesBody8FooterNavigations {
            items {
              footer_navigation_label {
                raw
                text
              }
            }
          }
          ... on PrismicMarketingSalesLandingPagesBody8FooterSocials {
            items {
              footer_social_icon {
                alt
                url
              }
              footer_social_url {
                url
              }
              icon {
                raw
                text
              }
            }
          }
        }
        body9 {
          ... on PrismicMarketingSalesLandingPagesBody9HeaderNavigations {
            items {
              header_navigation_item {
                raw
                text
              }
            }
          }
        }
        cancel_free_trial_note {
          raw
          text
        }
        discover_button_label {
          raw
          text
        }
        embedded_video_title {
          raw
          text
        }
        embedded_video_subtitle {
          raw
          text
        }
        faq_title {
          raw
          text
        }
        featured_in_title {
          raw
          text
        }
        footer_copyright {
          raw
          text
        }
        header_logo {
          alt
          url
        }
        header_logo_url {
          url
        }
        hero_logo {
          alt
          url
        }
        hero_image {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        hero_video {
          raw
        }
        hero_subtitle {
          raw
          text
        }
        hero_title {
          raw
          text
        }
        hero_subtitle {
          raw
          text
        }
        join_community_subtitle {
          text
        }
        join_community_title {
          text
        }
        join_us_content {
          raw
          text
        }
        unlimited_access_text {
          raw
          text
        }
        try_free_trial_button {
          raw
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        platform_support_title {
          raw
          text
        }
        see_prices_button_label {
          raw
          text
        }
        start_free_trial_button_label {
          raw
          text
        }
        stream_photo {
          alt
          url
        }
        stream_subtitle {
          text
        }
        stream_title {
          text
        }
        try_the_app_subtitle {
          raw
          text
        }
        try_the_app_title {
          text
        }
        try_the_app_image {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        workout_title {
          text
        }
        challenge_yourself_title {
          raw
          text
        }
        challenge_yourself_subtitle {
          raw
          text
        }
        challenge_yourself_features {
          challenge_yourself_feature {
            raw
            text
          }
        }
        challenge_yourself_image {
          url
          alt
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        sign_up_button {
          raw
          text
        }
        extra_info_image {
          url
          alt
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        extra_info_content {
          raw
          text
        }
      }
    }
  }
`

export default withPreview(MarketingSalesLandingPage)
