import { withUnpublishedPreview } from "gatsby-source-prismic"
import LandingPage from "../templates/LandingPage"
import MarketingSalesLandingPage from "../templates/MarketingSalesLandingPage"
import NotFoundPage from "@lesmills/gatsby-theme-public-pages/src/templates/NotFoundPage"

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    new_sales_landing_page: LandingPage,
    prismicNewSalesLandingPage: LandingPage,
    marketing_sales_landing_pages: MarketingSalesLandingPage,
    prismicMarketingSalesLandingPages: MarketingSalesLandingPage,
  },
})
